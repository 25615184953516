<template>
    <div class="content">
      <el-button type="primary" class="el-icon-download" @click='Download' style="margin-left: 10px;"> 下发追缴信息</el-button>
      <el-card style="margin-top:15px;width:100%" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>车牌号：</label>
                  <el-input v-model="searchForm.plateno" class="marg" style="width:140px"></el-input>
                </div>
              </el-col>
              <el-col :xs="24" :md="12" :lg="6">
                <div class="a">
                  <el-select v-model="searchForm.time_type" style="width:150px;margin-right:5px">
                      <el-option label="出场时间" :value="0"></el-option>
                      <el-option label="入场时间" :value="1"></el-option>
                  </el-select>
                   <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>卡类：</label>
                  <el-select v-model="searchForm.card_id" style="width:150px">
                      <el-option label="全部" :value="0"></el-option>
                      <el-option v-for="item in cardDataList" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div class="search">
                  <label>车型：</label>
                  <el-select v-model="searchForm.car_type" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="小车" :value="1"></el-option>
                    <el-option label="大车" :value="2"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="6" :lg="4">
                <div v-if="User.user_type!==5" class="search">
                  <label>车场：</label>
                  <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                    <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                    <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="12" :sm="8" :md="6" :lg="2">
                <div class="search">
                  <el-button type="primary" plain @click="Search">查询</el-button>
                </div>
              </el-col>
          </el-row>
      </el-card>
  
          <el-table :data='DataList' border style="width: 100%;font-size:13px"  max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini" @expand-change="expandChanged">
            <el-table-column type="expand" fixed>
              <template slot-scope="slot">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div>
                      <span style="margin-left:30px;font-size:15px">入场图片：</span>
                      <el-image v-if="slot.row.picdata.length>0" style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  :src='slot.row.picdata[0]' fit="fill" @click="ImgClick(0)"></el-image>
                      <el-dialog title="入场图片：" :visible.sync="inImgDialogVisible" :append-to-body='true'>
                          <img width="100%" :src="slot.row.picdata[0]" alt="">
                      </el-dialog>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <span style="margin-left:30px;font-size:15px">出场图片：</span>
                      <el-image v-if="slot.row.picdata.length>0" style="width: 150px; height: 150px;vertical-align:middle;margin-left:15px"  :src='slot.row.picdata[1]' fit="fill" @click="ImgClick(1)"></el-image>
                      <el-dialog title="出场图片：" :visible.sync="outImgDialogVisible" :append-to-body='true'>
                          <img width="100%" :src="slot.row.picdata[1]" alt="">
                      </el-dialog>
                    </div>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场" min-width="82px" fixed></el-table-column>
            <el-table-column align="center" prop="log_plateno" label="车牌号" min-width="82px" fixed></el-table-column>
            <el-table-column align="center" prop="owner_name" label="姓名"></el-table-column>
            <el-table-column align="center" prop="card_name" label="卡型"></el-table-column>
            <el-table-column align="center" prop="log_car_type" label="车型">
              <template slot-scope="scope">
                {{scope.row.log_car_type===0?'小车':'大车'}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="pk_name" label="车场"></el-table-column>
  
            <el-table-column align="center" label="进出场信息">
              <el-table-column align="center" prop="log_in_time" label="入场时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_in_time)}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="in_gate_name" label="入场通道"></el-table-column>
              <el-table-column align="center" prop="in_post_name" label="入场岗亭"></el-table-column>
              <el-table-column align="center" prop="in_user_name" label="入场操作员"></el-table-column>
              <el-table-column align="center" prop="log_out_time" label="出场时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_out_time)}}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="out_gate_name" label="出场通道"></el-table-column>
              <el-table-column align="center" prop="out_post_name" label="出场岗亭"></el-table-column>
              <el-table-column align="center" prop="out_user_name" label="出场操作员"></el-table-column>
              <el-table-column align="center" prop="log_time_len" label="停车时长" min-width="100px"></el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="log_charge" label="收费(元)">
                <template slot-scope="scope">
                  {{toDecimal2(scope.row.log_charge)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
               <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="RePay(scope.row,6)">追缴</el-link>
                        <el-link type="danger" @click="RePay(scope.row,7)">废除</el-link>
                    </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize" @size-change="handleSizeChange"></el-pagination>
  
    </div>
  </template>
  
  <script>
  import util from '../../../util/util'
  
  export default {
    data: function () {
      return {
        User:null,
        searchForm: {
          plateno: '',
          time_type: 0,
          pk_id:0,
          time: [],
          pay_type:0,
          card_id: 0,
          car_type: 0,
          pageSize: 15,
          pageNo: 1,
          total: 0
        },
        ParkDataList:[],
        CardTypeDataList: [],
        DataList: [],
  
        inImgDialogVisible: false,
        outImgDialogVisible:false,
      }
    },
    computed: {
      cardDataList(){
        if(this.User.user_type===5){
            return this.$store.state.CardDataList
          }
        else{
          return this.CardTypeDataList
        }
      },
      toDecimal2(){
          return function(num){
            if(num===null){num=0}
            return util.toDecimal2(num)
          }
      },
      FormData () {
        return function (time) {
          if(time===null||time===undefined||time==='')
            return ''
          else
            return util.FormateDate(time, 2)
        }
      },
      ChangeCardTypeReason () {
        return function (t) {
          if (t === -1) {
            return '一位多车，按临时车进'
          } else if (t === -2) {
            return '已过期，按临时车进'
          } else { return '' }
        }
      },
      CheckLogState () {
        return function (row) {
          if (row.log_state === 0 && (row.log_charge === 0 || row.log_charge === null) && (row.center_charge === 0 || row.center_charge === null)) {
            return '自动放行出场'
          }
          if (row.log_state === 0 && row.log_charge > 0) {
            return '现金支付'
          }
          if (row.log_state === 0 && (row.log_charge === null || row.log_charge === 0) && row.center_charge > 0) {
            return '场内预付'
          }
          if (row.log_state === 1) {
            if(row.log_charge===null){
              return '异常出场'
            }
            if(row.log_charge>0){
              return '超时未收费'
            }
            
          }
          if (row.log_state === 2) {
            return '脱机记录'
          }
          if (row.log_state === 3) {
            return 'app支付'
          }
          if (row.log_state === 4 && row.log_charge > 0) {
            return '未支付'
          }
          if (row.log_state === 0 && row.log_charge === 0 && (row.log_discount_type > 0 || row.md_dis_type > 0)) {
            return '优惠减免放行'
          }
          if (row.log_state === 5) {
              return '免费放行'
          }
        }
      }
    },
    created () {
      this.User = util.JesToken()
      this.getParkDataList()
    },
    methods: {
      getParkDataList(){
          if(this.User.user_type===5){
            if(this.$store.state.CardDataList.length===0){
                this.$store.dispatch('getCardDataList')
              }
              this.GetDataList()
          }
          else{
            util.Get('parkmanage/getparkdatalist').then(res => {
              if (res.rpStatus === 10000) {
                 this.ParkDataList = res.list
                 if(res.list.length>0){
                   this.searchForm.pk_id=res.list[0].pk_id
                   this.getCardDataList()
                   this.GetDataList()
                 }
              }
            })
          }
      },
      getCardDataList(){
        util.Get('card/getcarddatalist?id='+this.searchForm.pk_id).then(res => {
            if (res.rpStatus === 10000) {
              this.CardTypeDataList = res.list.filter(a => a.card_limit!==8)
            }
        })
      },
      parkChanged(value){
        this.searchForm.pk_id=value
        this.getCardDataList()
        this.searchForm.card_id=0
        this.GetDataList()
      },
  
      GetDataList () {
        this.searchForm.begintime=''
        this.searchForm.endtime=''
        this.searchForm.ischarge=true
        if(this.searchForm.time&&this.searchForm.time.length>0){
          this.searchForm.begintime=this.searchForm.time[0]
          this.searchForm.endtime=this.searchForm.time[1]
        }
        util.Get('exitlog/getexitlogdataList', this.searchForm).then(res => {
          if (res.rpStatus === 10000) {
            if (res.list.length > 0) {
              res.list.forEach(a => {
                a.picdata = []
              })
            }
            this.DataList = res.list
            this.searchForm.total = res.total
          }
        })
      },
      Search () {
        if(util.CheckUserAuth('4-2-1')){
            this.GetDataList()
          }
      },
      current_change (page) {
        this.searchForm.pageNo = page
        this.GetDataList()
      },
      handleSizeChange(pageSize){
        this.searchForm.pageSize=pageSize
        this.GetDataList()
      },
      expandChanged (row, expandedRows) {
        var arr = expandedRows.filter(a => a.log_id === row.log_id)
        if (arr.length > 0) {
          // 展开行
          if (row.picdata.length === 0 && ((row.log_in_picfullname !== null && row.log_in_picfullname !== undefined && row.log_in_picfullname !== '') || (row.log_out_picfullname !== null && row.log_out_picfullname !== undefined && row.log_out_picfullname !== ''))) {
              if(row.picdata.length===0){           
                util.Get('picture/GetParkPictrue?parkId='+row.park_id+'&logId='+row.log_id+'&picInName=' + row.log_in_picfullname + '&picOutName=' + row.log_out_picfullname).then(res=>{
                  if(res.rpStatus===10000){
                      var data=JSON.parse(res.list[0])
                      var log=row
                      if(row.park_id!==data.parkId||row.log_id!==data.logId){
                        var arr=this.DataList.filter(a=>a.park_id===data.parkId&&a.log_id==data.logId)
                        if(arr.length>0)
                          log=arr[0]
                      }
                      this.$set(log, 'picdata', [data.in_pic,data.out_pic])
                  }
                  else{
                    this.$message.error(res.rpMsg)
                  }
                })
            }
          }
        }
      },
  
      ImgClick (value) {
        this.inImgDialogVisible=false
        this.outImgDialogVisible=false
        if(value===0)
          this.inImgDialogVisible = true
        else
          this.outImgDialogVisible=true
      },
    
      RePay(row,opt){
        console.log(opt)
        let txt='确定对车辆“' + row.log_plateno + '”的追缴？'
        if(opt===7)
          txt='确定废除本次对车辆“' + row.log_plateno + '”的追缴？'

        this.$confirm(txt, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Post('exitlog/updatelogstate', {id:row.log_id,state:opt}).then(res => {
            if(res.rpStatus===10000){
              this.$message.success((opt===6?'追缴':'废除')+"成功")
              this.GetDataList()
            }
            else{
              this.$message.error((opt===6?'追缴':'废除')+"失败")
            }
          })
        }).catch(e => e)
      },


      Download(){
        util.Send('download_recharge',this.searchForm.pk_id)
      }
    }
  }
  </script>
  
  <style scoped>
    .content{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
  }
  .a{
    display:flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  .search{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
  }
  .search label{
    margin-right: 8px;
    width: 80px;
    text-align: right;
  }
  .el-table{
      margin-top: 10px;
      /* max-height: calc(100vh - 300px);
      height: calc(100vh - 250px); */
  }
  .el-link{
    margin-right: 10px;
    font-size: 12px;
}
  ::v-deep.el-table thead {
    color: #666666;
    font-weight: 500;
  }
  
  </style>
  